import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// import icon from "assets/img/icon-01-01.svg"

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import tabsStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx"
import SEO from "../../components/SEO"
import PreRegistration from "./Sections/PreRegistration"

class Membership extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO />
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/squad.jpeg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small>Joining the club</small>
            </h3>
            <iframe
              src="https://west-coast-beach-volleyball.class4kids.co.uk"
              width="100%"
              height="500"
              loading="lazy"
            />
            </GridItem>
            </GridContainer>
          </div>
      
        <Footer />
        </div>
      </div>
    )
  }
}

export default withStyles(tabsStyle)(Membership)
